<template>
  <div>
    <v-form @submit.prevent="submit">
      <v-text-field v-model="dataObject.titleUz" label="titleUz" clearable />

      <v-text-field v-model="dataObject.titleRu" label="titleRu" clearable />

      <v-text-field v-model="dataObject.titleEn" label="titleEn" clearable />

      <v-text-field
        v-model="dataObject.contentUz"
        label="contentUz"
        clearable
      />

      <v-text-field
        v-model="dataObject.contentRu"
        label="contentRu"
        clearable
      />

      <v-text-field
        v-model="dataObject.contentEn"
        label="contentEn"
        clearable
      />

      <v-file-input
        v-model="dataObject.bigImg"
        accept="image/*"
        label="Big image"
      />

      <!--            <v-file-input-->
      <!--                    @change="changeImages($event)"-->
      <!--                    accept="image/png, image/jpeg, image/webp"-->
      <!--                    label="images"-->
      <!--                    chips-->
      <!--                    multiple-->
      <!--            />-->
      <upload-image
        :data-images="imgList"
        :showEdit="false"
        :multiple="true"
        popup-text=""
        primaryText=""
        markIsPrimaryText=""
        :dragText="'Загрузка картинки'"
        :browseText="''"
        @upload-success="uploadPictureSmallSuccess"
        @before-remove="beforeRemovePictureSmall"
        idUpload="images"
        editUpload="productUpdateEditId"
        :disabled="loading"
      />

      <v-row no-gutters>
        <v-spacer />
        <v-btn @click="closeModal" color="primary" text class="mr-3"
          >Cancel</v-btn
        >
        <v-btn type="submit" color="primary" depressed>Save</v-btn>
      </v-row>
    </v-form>
  </div>
</template>

<script>
export default {
  props: {
    dataObject: Object,
    images: Array,
  },
  name: "Form",
  data: () => ({
    loading: false,
    imgList: [],
  }),
  methods: {
    submit() {
      const formData = new FormData();
      for (let key in this.dataObject) {
        if (key === "images") {
          this.dataObject[key].forEach((image, i) => {
            formData.append("images", image);
          });
        } else {
          formData.append(key, this.dataObject[key]);
        }
      }

      this.$emit("send-about", formData);
    },

    uploadPictureSmallSuccess(formData, index, fileList) {
      this.imgList = fileList;
      this.dataObject.images.push(formData.get("file"));
    },
    async beforeRemovePictureSmall(index, done, fileList) {
      if (this.dataObject.images[index]) {
        await this.deleteStoredImage(this.dataObject.images[index]);
      }
      this.dataObject.images.splice(index, 1);
      done();
    },
    async deleteStoredImage(imageName) {
      let params = {
        url: imageName,
      };
      return this.$api
        .post("image/delete", params)
        .then((res) => {
          if (res) {
            return res;
          } else {
            return false;
          }
        })
        .catch((error) => {
          return false;
        })
        .finally((item) => {
          ///
        });
    },
    closeModal() {
      this.$emit("close-modal");
    },
  },
  mounted() {
    this.imgList = this.images;
  },
};
</script>

<style scoped></style>
