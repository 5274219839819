<template>
  <div>
    <div class="d-flex justify-space-between align-center mb-6">
      <div style="width: 100px">
        <v-autocomplete
          v-model="lang"
          :rules="[() => !!lang || 'This field is required']"
          :items="languages"
          label="Lang"
          required
          solo
          hide-details
          dense
        />
      </div>
      <v-btn
        v-if="getAbout"
        color="primary"
        elevation="2"
        @click="openForm(getAbout, createAboutObject)"
        >update about</v-btn
      >
      <v-btn
        v-else
        color="primary"
        elevation="2"
        @click="openForm(null, createAboutObject)"
        >add about</v-btn
      >
    </div>

    <v-card>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                About
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="font-weight-medium">Title</td>
              <td>
                {{
                  lang === "uz"
                    ? getAbout.titleUz
                    : lang === "ru"
                    ? getAbout.titleRu
                    : lang === "en"
                    ? getAbout.titleEn
                    : ""
                }}
              </td>
            </tr>
            <tr>
              <td class="font-weight-medium">Content</td>
              <td
                v-html="
                  lang === 'uz'
                    ? getAbout.contentUz
                    : lang === 'ru'
                    ? getAbout.contentRu
                    : lang === 'en'
                    ? getAbout.contentEn
                    : ''
                "
              ></td>
            </tr>
            <tr>
              <td class="font-weight-medium">bigImg</td>
              <td>
                <div class="partner__img">
                  <img :src="baseURL + getAbout.bigImg" />
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <v-btn block color="success" @click="showImages = !showImages">
        <div>
          Child images
        </div>

        <v-spacer />

        <v-btn icon color="white">
          <v-icon>{{
            showImages ? "mdi-chevron-up" : "mdi-chevron-down"
          }}</v-icon>
        </v-btn>
      </v-btn>

      <v-expand-transition>
        <div v-show="showImages">
          <v-divider />

          <v-card>
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr v-for="(img, index) in aboutImages">
                    <td class="font-weight-medium">{{ index + 1 }}</td>
                    <td>
                      <div class="service__img">
                        <img :src="baseURL + img" />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </div>
      </v-expand-transition>
    </v-card>

    <Modal v-model="modal" width="1140px" title="About form">
      <Form
        v-if="modal"
        :data-object="dataObject"
        :images="images"
        @close-modal="modal = false"
        @send-about="sendAbout"
      />
    </Modal>
  </div>
</template>

<script>
import Form from "./Form";
import formModal from "@/mixins/formModal";
import deleteModal from "@/mixins/deleteModal";
import { baseURL } from "@/services/api";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "List",
  data: () => ({
    baseURL,
    lang: "uz",
    languages: ["uz", "ru", "en"],
    showImages: false,
    images: [],
    aboutImages: [],
  }),
  components: { Form },
  computed: {
    ...mapGetters({
      getAbout: "getAbout",
    }),
  },
  mixins: [formModal, deleteModal],
  methods: {
    ...mapActions({
      fetchAbout: "fetchAbout",
      updateAbout: "updateAbout",
    }),

    createAboutObject(about = null) {
      const object = {
        titleUz: about ? about.titleUz : "",
        titleRu: about ? about.titleRu : "",
        titleEn: about ? about.titleEn : "",
        contentUz: about ? about.contentUz : "",
        contentRu: about ? about.contentRu : "",
        contentEn: about ? about.contentEn : "",
        bigImg: about.bigImg,
        images: about.images,
      };

      this.images = about.images.map((img) => ({
        path: this.baseURL + img,
        src: img,
      }));

      return object;
    },

    async sendAbout(data) {
      await this.updateAbout(data);
      await this.fetchAbout();
      this.aboutImages = this.getAbout.images;
      this.modal = false;
    },
  },
  async mounted() {
    await this.fetchAbout();
    this.aboutImages = this.getAbout.images;
  },
};
</script>

<style scoped lang="scss">
.partner__img {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  overflow: hidden;

  & img {
    height: 45px;
  }
}
</style>
